var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "阈值调整",
            visible: _vm.showSetDoorbell,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSetDoorbell = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "setForm",
              attrs: { "label-width": "150px", model: _vm.setForm },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "门铃异响记录时间",
                    prop: "doorbellTime",
                    rules: _vm.rules.doorbellTime,
                  },
                },
                [
                  _c("el-time-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "picker-options": {
                        selectableRange: "00:00:00 - 23:59:59",
                      },
                      "is-range": "",
                      "value-format": "HH:mm",
                      "range-separator": "至",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      placeholder: "选择时间范围",
                    },
                    model: {
                      value: _vm.doorbellTime,
                      callback: function ($$v) {
                        _vm.doorbellTime = $$v
                      },
                      expression: "doorbellTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showSetDoorbell = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.setReq("setForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }