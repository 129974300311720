<template>
 <!-- 客房事件页面 -->
 <!-- 头部选择器 -->
 <div class="my-box">
  <el-card shadow="always" class="my-card topS">
   <el-form label-width="80px">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="3">
      <el-form-item label="品牌">
       <el-select v-model="searchForm.brandId" @change="selectOne" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="5">
      <el-form-item label="门店">
       <el-select v-model="searchForm.hotelId" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in hotelSelectData" :key="index" :label="item.name" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="房间号">
       <el-input placeholder="请输入房间号" v-model.trim="searchForm.roomNumber" clearable class="my-input"></el-input>

      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="UUID">
       <el-input placeholder="请输入UUID" v-model.trim="searchForm.mainboardId" clearable class="my-input"></el-input>

      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="主机类型">
       <el-select v-model="searchForm.mainframeType" clearable placeholder="请选择">
        <el-option v-for="(item,index) in hostType" :key="index" :label="item" :value="item"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="版本号">
       <el-input placeholder="请输入版本号" v-model.trim="searchForm.version" clearable class="my-input"></el-input>

      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="异常类型">
       <el-select v-model="searchForm.warningType" clearable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.abnormals" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="异常状态">
       <el-select v-model="searchForm.state" clearable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.abnormalStatus" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="6">
      <el-form-item label="触发时间">
       <el-date-picker v-model="searchForm.times" type="daterange" :default-time="defaultTime" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
     </el-col>

     <el-col :span="10">
      <div class="grid-content reset-button">
       <el-button size="mini" type="primary" @click="handleSearch">查询</el-button>
       <el-button size="mini" @click="reset">重置</el-button>
       <el-button size="mini" v-if="showexport" @click="exportExcel(0)" :loading="downLoading">导出</el-button>
       <el-button size="mini" type="primary" @click="openSetDiaoLog">阈值调整</el-button>
      </div>
     </el-col>
    </el-row>
   </el-form>
  </el-card>

  <el-card>
   <el-table :data="tablist" v-loading="loading" id="tab" :height="height" style="width: 100%">
    <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :label="item.label">
     <template slot-scope="scope">
      <div v-if="item.solt_TEXT">
       {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
      </div>
      <div v-else-if="item.value=='longTimes'">
       {{showLongTimes(scope.row.st,scope.row.et)}}
      </div>
      <div v-else>
       {{scope.row[item.value]}}
      </div>
     </template>
    </el-table-column>
   </el-table>
   <div class="page">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 50, 300]" :page-size="pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination>
   </div>
  </el-card>
  <setTime ref="setTime" />
 </div>
</template>
<script>
import { export_json_to_excel } from "@/vendor/Export2Excel.js";
import { getLongDateTime, showLabel, getDay } from "@/api/common.js";

import {
 warningList,
 getBrandSelect,
 getHotelSelect,
 warningSettingGet,
} from "@/api";
import setTime from "./setTime.vue";
const typeLists = {
 abnormals: [
  {
   label: "门铃异响",
   value: 0,
  },
  {
   label: "设备离线",
   value: 1,
  },
  {
   label: "自动重启",
   value: 2,
  }
 ],
 abnormalStatus: [
  {
   label: "持续中",
   value: 0,
  },
  {
   label: "已结束",
   value: 1,
  },
 ],
 sbstatus: [
  {
   label: "在线",
   value: "on",
  },
  {
   label: "离线",
   value: "off",
  },
 ],
 // ckList:this.ckList
};
export default {
 components: {
  setTime,
 },
 data() {
  return {
   height: null,
   tablist: [],
   execlTab: [
    {
     label: "门店",
     value: "hname",
    },
    {
     label: "房间号",
     value: "roomNo",
     width: 80,
    },
    {
     label: "UUID",
     value: "mid",
      width: 280,
    },
    {
     label: "版本号",
     value: "ver",
    },
    {
     label: "插卡状态",
     value: "ident",
     solt_TEXT: "ckList",
     width: 100,
    },
    {
     label: "主机类型",
     value: "mtype",
     width: 100,
    },

    {
     label: "异常类型",
     value: "wtype",
     solt_TEXT: "abnormals",
     width: 80,
    },
    {
     label: "开始时间",
     value: "st",
     width: 140,
    },
    {
     label: "结束时间",
     value: "et",
     width: 140,
    },
    {
     label: "持续时长",
     value: "longTimes",
     width: 150,
    },
    {
     label: "当前设备状态",
     value: "online",
     solt_TEXT: "sbstatus",
     width: 100,
    },
    {
     label: "异常状态",
     value: "sta",
     solt_TEXT: "abnormalStatus",
     width: 100,
    },
   ],
   defaultTime: ["00:00:00", "23:59:59"],
   loading: false,
   downLoading: false,
   total: 0,
   pageNum: 1,
   pageSize: 10,
   // 查询的数据
   searchForm: {
    brandId: null,
    hotelId: null,
    mainframeType: null,
    roomNumber: null,
    status: null,
    operationType: null,
    times: null,
   },
   listQuery: {},
   brandSelectData: null,
   hotelSelectData: null,
  };
 },

 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 295;
 },
 computed: {
  typeLists: {
   get: function () {
    let obj = typeLists;
    obj.ckList = this.$store.state.ckList;
    return obj;
   },
   set: function (value) {},
  },
  hostType() {
   return this.$store.state.hostType;
  },
  showexport: function () {
   return this.$store.state.roleMenu.includes("exceptionLog:export");
  },
 },

 created() {
  this.initTimes();
  // this.getList();
  this.initialize();
 },
 methods: {
  initTimes() {
   let today = getDay();
   this.searchForm.times = [
    `${today} ${this.defaultTime[0]}`,
    `${today} ${this.defaultTime[1]}`,
   ];
   //this.handleSearch();
  },
  // 根据值显示信息
  showLabelText(val, list) {
   return showLabel(val, list);
  },
  // 时长
  showLongTimes(start, end) {
   return getLongDateTime(start, end);
  },
  //  打开设置
  openSetDiaoLog() {
   warningSettingGet()
    .then((res) => {
     if (res.data.code == "000000") {
      if (res.data) {
       let data = res.data.data;
       this.$refs.setTime.openSet(data);
      } else {
       this.$refs.setTime.openSet();
      }
     }
    })
    .catch((err) => {
    });
  },
  // 导出
  exportExcel(i) {
   this.downLoading = true;
   if (this.loading) {
    if (i < 5) {
     setTimeout(() => {
      this.exportExcel(i);
     }, 1000);
    }
   } else {
    if (!this.tablist || this.tablist.length == 0) {
     this.$$message.warning("未加载到数据");
     return;
    }
    let tHeader = [];
    let filterVal = [];
    this.execlTab.forEach((v) => {
     tHeader.push(v.label);
     filterVal.push(v.value);
    });
    let list = this.getExeclData();
    let data = this.formatJson(filterVal, list);
    setTimeout(() => {
     export_json_to_excel(tHeader, data, "异常记录");
     this.downLoading = false;
    }, 500);
   }
  },
  // d导出execl数据转换
  getExeclData() {
   let arr = new Array();
   this.tablist.forEach((v) => {
    let obj = Object.assign({}, v);
    obj.warningType = showLabel(v.warningType, this.typeLists.abnormals);
    obj.identity = showLabel(v.identity, this.typeLists.ckList);
    obj.longTimes = this.showLongTimes(v.startTime, v.endTime);
    obj.basicstatus = showLabel(v.basicstatus, this.typeLists.sbstatus);
    obj.state = showLabel(v.state, this.typeLists.abnormalStatus);
    arr.push(obj);
   });
   return arr;
  },
  formatJson(filterVal, jsonData) {
   return jsonData.map((v) => filterVal.map((j) => v[j]));
  },
  initialize() {
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
    }
   });
  },
  getSearchData() {
   this.listQuery = {};
   for (let key in this.searchForm) {
    if (key == "times") {
     if (this.searchForm.times) {
      this.listQuery.startDate = this.searchForm.times[0];
      this.listQuery.endDate = this.searchForm.times[1];
     } else {
      this.listQuery.startDate = undefined;
      this.listQuery.endDate = undefined;
     }
    } else if (this.searchForm[key] || this.searchForm[key] === 0) {
     this.listQuery[key] = this.searchForm[key];
    }
   }
  },
  // 获取门店下拉框 -- 2
  selectOne(id) {
   this.searchForm.hotelId = null;
   this.searchForm.roomNumber = null;
   this.hotelSelectData = null;
   getHotelSelect(id).then((res) => {
    if (res.data.data) {
     this.hotelSelectData = res.data.data;
    } else {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   });
  },
  // 搜索
  handleSearch() {
   this.pageNum = 1;
   this.getSearchData();
   this.getList();
  },
  // 重置
  reset() {
   this.pageNum = 1;
   this.searchForm = {};
   this.getSearchData();
   this.getList();
  },
  handleSizeChange(val) {
   this.pageSize = val;
   this.getList();
  },
  handleCurrentChange(val) {
   this.pageNum = val;
   this.getList();
  },
  // 列表数据
  getList() {
   this.loading = true;
   this.listQuery['pageNum'] = this.pageNum
   this.listQuery['pageSize'] = this.pageSize
   warningList(this.listQuery)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data.rows;
      this.total = res.data.data.total;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
 },
};
</script>
<style lang="scss" >
.my-box {
 .my-card {
  margin-bottom: 10px;
 }

 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }
 .el-col {
  border-radius: 4px;
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;
  .el-input__inner {
   max-width: 190px;
  }
  span {
   font-size: 14px;
  }
 }

}
</style>


