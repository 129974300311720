<template>
 <div class="my-box">

  <el-dialog title="阈值调整" :visible.sync="showSetDoorbell" width="30%">
   <el-form label-width="150px" ref="setForm" :model="setForm">

    <el-form-item label="门铃异响记录时间" prop="doorbellTime" :rules="rules.doorbellTime">
     <el-time-picker style="width:100%" :picker-options="{
       selectableRange:'00:00:00 - 23:59:59'
     }" is-range value-format="HH:mm" v-model="doorbellTime" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
     </el-time-picker>
    </el-form-item>
    <!-- <el-form-item label="描述">
     <el-input type="textarea" placeholder="请输入内容" v-model="textarea" maxlength="30" show-word-limit />
    </el-form-item> -->
   </el-form>
   <span slot="footer" class="dialog-footer">
    <el-button size="small" @click="showSetDoorbell = false">取 消</el-button>
    <el-button type="primary" @click="setReq('setForm')">确 定</el-button>
   </span>
  </el-dialog>
 </div>
</template>
<script>
import { warningSettingAdd, warningSettingUpdate } from "@/api";
export default {
 data() {
  let changeTime = (rule, value, callback) => {
   if (!this.doorbellTime) {
    callback(new Error("请选择时间范围"));
   } else {
    callback();
   }
  };
  return {
   showSetDoorbell: false,
   rules: {
    doorbellTime: [
     { validator: changeTime, trigger: ["blur", "change"] },
    ],
   },
   doorbellTime: null,
   setForm: {},
  };
 },

 created() {},
 methods: {
  setReq(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     let data = this.reqData(this.setForm);
     this.updateReq(data);
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
  openSet(data) {
   this.showSetDoorbell = true;
   if (data) {
    this.setForm = Object.assign({}, data);
    this.doorbellTime = [data.start, data.end];
   } else {
    this.setForm = {};
    this.doorbellTime=null
   }
  },
  reqData(val) {
   return {
    start: this.doorbellTime[0],
    end: this.doorbellTime[1]
   };
  },
  addReq(data) {
   this.loading = true;
   warningSettingAdd(data)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("设置成功");
      this.showSetDoorbell = false;
     } else {
      this.$message.warning("设置失败");
     }
    })
    .catch((err) => {
     this.loading = false;
     this.$message.warning("设置失败");
    });
  },
  updateReq(data) {
   this.loading = true;
   warningSettingUpdate(data)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("修改成功");
      this.showSetDoorbell = false;
     } else {
      this.$message.warning("修改失败");
     }
    })
    .catch((err) => {
     this.loading = false;
     this.$message.warning("修改失败");
    });
  },
 },
};
</script>
<style lang="scss" >
.my-box {
 .my-card {
  margin-bottom: 10px;
 }

 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }
 .el-col {
  border-radius: 4px;
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;
  .el-input__inner {
   max-width: 190px;
  }
  span {
   font-size: 14px;
  }
 }

}
</style>


